import { Spinner } from "react-bootstrap";
import StudentTable from "./StudentTable";
import ScreenHeader from "./ScreenHeader";
import TablePaginator from "../../../components/Pagination/TablePaginator";
import React from "react";
import { useSelector } from "react-redux";

// Hooks
import useDebounce from "../../../hooks/useDebounce";

// Components
import Loading from "../loading";

// Styles
import "./_index.scss";

// Redux
import { RootState } from "../../../redux/reducers";

// GraphQl
import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from "../../../graphql/custom-queries";
import { getOrList } from "../../../components/dynamicqueries";

// Utils
import handleSorting from "./utils/handleSorting";
import getBookmarkedCandidates from "./utils/getBookmarkedCandidates";

// Services
import EventService from "../../../services/EventService/event.service";
import { StudentRowType } from "./types";

function StudentList({
  setSelectedTab,
  institutionId,
  initialSortAlgorithm,
  isDashboard,
  rowLimit,
}: any) {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isTableLoading, setIsTableLoading] = React.useState<boolean>(false);

  interface RefreshingStatus {
    status: boolean;
  }

  /* 
    refreshingCSVData lets screenheader know to refresh export data. 
    we use an object instead of a boolean for debouncing
  */
  const [refreshingCSVData, setRefreshingCSVData] =
    React.useState<RefreshingStatus>({ status: false });
  const debouncedRefreshingCSVData = useDebounce(refreshingCSVData, 500);
  const [sortingAlgorithm, setSortingAlgorithm] = React.useState({
    propertyKey: "",
    sortDirection: "",
  });

  // studentsList = [{userId,userSessionId,perseveranceScore,perseveranceScoreBand,leadershipScore,leadershipScoreBand,innovativeThinkingScore,innovativeThinkingScoreBand,communicationScore,communicationScoreBand,enrollmentYear,ageGroup,comment} , {}]
  const [studentsList, setStudentsList] = React.useState<any>(null);
  const [originalList, setOriginalList] = React.useState<any>(null);
  const [pagination, setPagination] = React.useState({ start: 0, end: 0 });
  const { userId } = useSelector((state: RootState) => state.user);

  // Bookmarks
  const [currentBookmarks, setCurrentBookmarks] = React.useState<any[]>([]);

  // Set initial bookmarks
  React.useEffect(() => {
    const setInitialHEIBookmarks = async () => {
      const HEIBookmarks = await getBookmarkedCandidates(userId);
      setCurrentBookmarks(HEIBookmarks);
    };

    setInitialHEIBookmarks();
  }, []);

  React.useEffect(() => {
    const makeRequest = async () => {
      if (isLoading === true) {
        const result = await newStudentList();
        const lastIndex = result.length;
        const paginationEnd = lastIndex < 10 ? lastIndex : 10;
        setStudentsList(result);
        setOriginalList(result);
        setPagination({ start: 0, end: paginationEnd });
        setLoading(false);
      }
    };

    makeRequest();

    EventService.page({
      type: "enter",
      page: "HEI Student List",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "HEI Student List",
      });
    };
  }, []);

  const newStudentList = async () => {
    const filterVariable = {
      limit: 10000,
      filter: {
        and: [
          { overallScore: { gte: 0 } },
          { institution: { eq: institutionId } },
        ],
      },
    };
    const data = await getOrList(
      customQueries.listOfStudentList,
      "searchUserScores",
      filterVariable,
    );

    return data;
  };

  const sortStudents = async () => {
    const { propertyKey, sortDirection } = sortingAlgorithm;

    setIsTableLoading(true);

    if (sortDirection !== "search") {
      const sortedStudents = await handleSorting({
        studentsList,
        propertyKey,
        sortDirection,
        userId,
      });
      //For dashboard limit the number of students to rowLimit ie 5
      setStudentsList(
        isDashboard ? sortedStudents.slice(0, rowLimit) : sortedStudents,
      );
      setIsTableLoading(false);
    } else if (sortDirection === "search" && propertyKey.length > 0) {
      console.log("searching", propertyKey);
      setStudentsList(
        originalList.filter(
          ({
            userAssessmentState: {
              user: { name },
            },
          }: any) => name.toLowerCase().indexOf(propertyKey) > -1,
        ),
      );
      setIsTableLoading(false);
    } else if (sortDirection === "search" && propertyKey.length === 0) {
      console.log("searching", propertyKey);
      setStudentsList(originalList);
      setIsTableLoading(false);
    }
  };

  // We handle sorting whenever sortingAlgorithm changes.
  React.useEffect(() => {
    if (sortingAlgorithm.propertyKey) {
      sortStudents();
    }
  }, [sortingAlgorithm]);

  // Set initial sorting algorithm when viewed from dashboard
  React.useEffect(() => {
    if (isDashboard && studentsList?.length > 0) {
      setSortingAlgorithm({
        propertyKey: initialSortAlgorithm?.propertyKey,
        sortDirection: initialSortAlgorithm?.sortDirection,
      });
    }
  }, [studentsList?.length > 0]);
  // PAGINATION HANDLERS

  const handlePagination = (startingPosition: number, range: number) => {
    // Mind that we're storing index positions.
    const start = startingPosition - 1;
    const end =
      startingPosition + range > studentsList.length
        ? studentsList.length
        : start + range;

    EventService.track({
      event_type: "user",
      event_name: "hei_list_update",
      user_type: "hei",
      event_data: {
        start,
        end,
        listedStudents: studentsList.slice(start, end),
      },
    });

    setPagination({ start, end });
  };

  const handleRangeChange = (startingPosition: number, range: number) => {
    // adjust for index
    const start = startingPosition - 1;
    const end =
      start + range > studentsList.length ? studentsList.length : start + range;

    setPagination({ start, end });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="screenContent container">
      <ScreenHeader
        setSelectedTab={setSelectedTab}
        studentList={studentsList}
        debouncedRefreshingCSVData={debouncedRefreshingCSVData}
        setRefreshingCSVData={setRefreshingCSVData}
        isDashboard={isDashboard}
        sortingHandler={setSortingAlgorithm}
      />
      <StudentTable
        isTableLoading={isTableLoading}
        sortingHandler={setSortingAlgorithm}
        allStudentsList={studentsList}
        setStudentsList={setStudentsList}
        setRefreshingCSVData={setRefreshingCSVData}
        currentStudentsList={studentsList.slice(
          pagination.start,
          pagination.end,
        )}
        isDashboard={isDashboard}
      />
      {!isDashboard && (
        <TablePaginator
          onNextPage={handlePagination}
          onPrevPage={handlePagination}
          onRangeChange={handleRangeChange}
          itemTotal={studentsList.length}
        />
      )}
    </div>
  );
}

export default StudentList;
