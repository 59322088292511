// Packages
import React from "react";

/**
 * usePrevious.
 *
 * A custom hooks which returns the previous props upon re-render
 *
 * @param value
 * @returns
 */

function usePrevious(value: any) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;
