/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "Events",
            "endpoint": "https://3ew7vv8v0f.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "psqmmaiservice",
            "endpoint": "https://8igmi8fo34.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://magtxsftw5bdzoq44zp6dourle.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-au5etsbqereq5heat4hyazjzmi",
    "aws_cognito_identity_pool_id": "us-east-1:609b0d5b-97d4-4f3c-8228-5bb3ccfc55d3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KvEvjOUFk",
    "aws_user_pools_web_client_id": "61pt58tgaatsbppiroiaos1sju",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ets-psq-amplify-bucket184721-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
