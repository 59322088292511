export type AssessmentType = {
  id: string;
  name?: string;
  description?: string;
  sections?: any;
};

export type AssessmentActionTypes = StoreAssessmentAction;

export const STORE_ASSESSMENT = "STORE_ASSESSMENT";

export interface StoreAssessmentAction {
  type: typeof STORE_ASSESSMENT;
  payload: any;
}
