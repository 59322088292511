// Routing
import { Route, Routes, Navigate } from "react-router-dom";

// Screens
import Assessment from "../screens/assessment";
import Report from "../screens/report";
import Profile from "../screens/profile";
import UserState from "../screens/userstate";

function RoutesStudent() {
  return (
    <Routes>
      <Route path="*" element={<Navigate replace to="/assessment" />} />
      <Route path="/assessment/*" element={<Assessment />} />
      <Route path="/report/*" element={<Report />} />
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/" element={<Navigate replace to="/assessment" />} />
      <Route path="/userstate" element={<UserState />} />
    </Routes>
  );
}

export default RoutesStudent;
