// Packages
import React from "react";

// Interfaces
import { ModalInterface } from "./types";

const ModalContext = React.createContext<ModalInterface>({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
});

export { ModalContext };
