// Packages
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

const ModalSupportCareers = ({ onRequestClose }: any) => {
  const handleResourceHub = () => {
    onRequestClose();
  };

  return (
    <Modal className="modal-supportCareers" show={true} onHide={onRequestClose}>
      <Modal.Header className="no-border" closeButton>
        <Modal.Title>View Careers</Modal.Title>
      </Modal.Header>
      <hr className="border-line"></hr>

      <Modal.Body className="no-border">
        This feature is in the works but we’ve let the research and development
        team know you’re interested in it!
      </Modal.Body>

      <Modal.Footer className="no-border">
        <Button
          bsPrefix="psq-btn"
          className="psq-btn-modal"
          variant="primary"
          onClick={handleResourceHub}
        >
          Go back to Resource Hub
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSupportCareers;
