// Models
import { Usertype } from "../models";

const institutionHosts = [
  "gradinsights-dev.ai.ets.org",
  "gradinsights-test.ai.ets.org",
  "gradinsights-staging.ai.ets.org",
  "gradinsights-demo.ai.ets.org",
  "gradinsights.ai.ets.org",
  "localhost:3001",
  "heidevelop.d3k0761aabf3y3.amplifyapp.com",
  "ets-ins.d3k0761aabf3y3.amplifyapp.com",
  "institution.skilltrackapp.com",
];

export function determineUsertype() {
  const { host, search } = window.location;
  const searchParams = new URLSearchParams(search);

  if (
    institutionHosts.includes(host) ||
    searchParams.get("usertype")?.toUpperCase() === Usertype.INSTITUTION
  ) {
    sessionStorage.setItem("userGroup", "INSTITUTION");
    return Usertype.INSTITUTION;
  } else {
    sessionStorage.setItem("userGroup", "STUDENT");
  }

  return Usertype.STUDENT;
}

const defaults = {
  determineUsertype,
};

export default defaults;
