// Packages
import React from "react";
import { API } from "aws-amplify";

// Data
import * as customQueries from "../../../../graphql/custom-queries";
import { DIMENSIONS } from "../../../../enumerations";
import { ReactComponent as ManWithFlag } from "../../../../assets/reskin/icons/man-with-flag.svg";
import { ReactComponent as ChatBubble } from "../../../../assets/reskin/icons/chat-bubble.svg";
import { ReactComponent as SmallMountain } from "../../../../assets/reskin/icons/small-mountain.svg";
import { ReactComponent as LightBulb } from "../../../../assets/reskin/icons/light-bulb.svg";

// Services
import EventService from "../../../../services/EventService/event.service";
import { Spinner } from "react-bootstrap";
import BarReskin from "./BarReskin";

type GraphType = {
  traitName: any;
  traitData: any;
  institutionId: any;
};

function TraitGraph({ traitName, traitData, institutionId }: GraphType) {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [userScores, setUserScores] = React.useState<any>([]);
  const [totalStudents, setTotalStudents] = React.useState<number>(0);

  React.useEffect(() => {
    setLoading(true);
    setUserScores([]);
    getUserScores();
  }, [traitName]);

  React.useEffect(() => {
    if (isLoading && traitData.number.length * 3 === userScores.length) {
      setLoading(false);
    }
  }, [userScores]);

  async function getUserScores() {
    const { number } = traitData;
    await number.forEach(async (numb: any, index: any) => {
      const low = await determineUserScores(
        traitData.type,
        numb,
        0,
        institutionId,
      );
      const med = await determineUserScores(
        traitData.type,
        numb,
        1,
        institutionId,
      );
      const high = await determineUserScores(
        traitData.type,
        numb,
        2,
        institutionId,
      );
      const newScores = [low, med, high];
      const totalStudents = newScores.reduce((acc: any, curr: any) => {
        return acc + curr.total;
      }, 0);
      setTotalStudents(totalStudents);
      setUserScores((oldArray: any) => [...oldArray, ...newScores]);
    });
  }

  const determineUserScores = async (
    type: any,
    number: any,
    score: any,
    institutionId: any,
  ) => {
    let filter = {
      and: [
        { [number]: { eq: score } },
        { overallScore: { gte: 0 } },
        { institution: { eq: institutionId } },
      ],
    };

    const response = await API.graphql({
      query: customQueries.searchUserScoresCount,
      variables: { limit: 10000, filter: filter },
    });
    const result = JSON.parse(JSON.stringify(response));

    return {
      type: type,
      number: number,
      score: score,
      total:
        result.data.searchUserScores.total == null
          ? 0
          : result.data.searchUserScores.total,
    };
  };

  const studentPerformance = () => {
    let sP = traitData.studentPerformance;

    // demonstrating ascending, where score == 2
    var sortDemonstratingData = userScores
      .filter((uS: any, index: number) => {
        return uS.score === 2;
      })
      .sort((a: any, b: any) => {
        return b.total - a.total;
      });

    // developing ascending, where score == 0
    const sortDevelopingData = userScores
      .filter((uS: any, index: number) => {
        return uS.score === 0;
      })
      .sort((a: any, b: any) => {
        return b.total - a.total;
      });

    const developingSubtraitnumber: string =
      sortDevelopingData[0].number.toUpperCase();

    const demonstraingSubtraitnumber: any =
      sortDemonstratingData[0].number.toUpperCase();

    sP = sP.replace(
      "[x]",
      `${(DIMENSIONS as any)[demonstraingSubtraitnumber]}`,
    );

    sP = sP.replace("[y]", `${(DIMENSIONS as any)[developingSubtraitnumber]}`);

    EventService.track({
      event_type: "user",
      event_name: "hei_trait_performance",
      user_type: "hei",
      event_data: {
        trait: traitName,
        studentPerformance: sP,
      },
    });

    return sP;
  };

  const titleIcon =
    traitName === "leadership" ? (
      <ManWithFlag />
    ) : traitName === "effectivecommunication" ? (
      <ChatBubble />
    ) : traitName === "perseverance" ? (
      <SmallMountain />
    ) : (
      <LightBulb />
    );

  return (
    <>
      <>
        <h1
          style={{
            fontSize: 28,
            color: "#353C3E",
            fontWeight: 700,
            margin: "0 1rem 1rem",
          }}
        >
          {traitData.title}
        </h1>
        <div className="traitGraph">
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" className="" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <p className={`graph-title trait-color-${traitName}`}>
                <span
                  className={`graph-title-left-icon ${traitName}-background`}
                >
                  {titleIcon}{" "}
                </span>{" "}
                Individual Performance on {traitData.title} Traits
              </p>
              <p style={{ margin: "1rem 4rem" }}>{studentPerformance()}</p>

              <div className="bar-chart">
                <div className="bar-chart-header d-flex justify-content-between mb-2">
                  <span>Percentage of students</span>{" "}
                  <span>Number of students</span>
                </div>
                <div className="bar-chart-data">
                  {/* {traitData.number.map((number: any, index: any) => {
                    return (
                      <div key={index} className="trait-bar-chart">
                        <div className="bar-header">
                          {traitData.dimension[index]}
                        </div>

                        {userScores.filter(
                          (subtrait: any, i: any) => subtrait.number === number,
                        ).length > 0 && (
                            <Bar
                              scores={userScores.filter(
                                (subtrait: any, i: any) =>
                                  subtrait.number === number,
                              )}
                              dimension={number}
                            />
                          )}
                      </div>
                    );
                  })} */}
                  <BarReskin
                    scores={userScores}
                    traitData={traitData}
                    totalStudents={totalStudents}
                  />
                </div>
                <div className="score-level">
                  {/* make the classname dynamic */}
                  <div
                    className={`low-level ${traitData.type}-background-developing`}
                  ></div>
                  <div>Developing</div>
                  <div
                    className={`medium-level ${traitData.type}-background-approaching`}
                  ></div>
                  <div>Approaching</div>
                  <div
                    className={`high-level ${traitData.type}-background`}
                  ></div>
                  <div>Demonstrating</div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
}

export default TraitGraph;
