// Packages
import React from "react";
import { Button, Modal } from "react-bootstrap";

const ModalStudentResource = ({ onRequestClose, closePopUp }: any) => {
  const close = () => {
    closePopUp();
    onRequestClose();
  };

  return (
    <Modal
      className="modal-student-resource"
      show={true}
      onHide={onRequestClose}
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title>Success!</Modal.Title>
      </Modal.Header>

      <hr className="border-line"></hr>

      <Modal.Body className="no-border">
        Your support message(s) has been sent to student.
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          bsPrefix="psq-btn"
          className="psq-btn-modal"
          variant="primary"
          onClick={close}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalStudentResource;
