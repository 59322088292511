// Packages
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { API } from "aws-amplify";
import { useState, useEffect } from "react";

// Assets
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/reskin/icons/search.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/reskin/icons/clear.svg";
import { ReactComponent as ChevronRight } from "../../../../assets/reskin/icons/chevron-right.svg";
// Components
import { SkillsLegend } from "./SkillsLegend";

// Services
import EventService from "../../../../services/EventService/event.service";

// Styles
import "./_index.scss";

// Redux
import { RootState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";

// GraphQL
import * as queries from "../../../../graphql/queries";

// Types
import { StudentListCSVItem } from "./utils/types";

function ScreenHeader({
  studentList,
  setSelectedTab,
  debouncedRefreshingCSVData,
  setRefreshingCSVData,
  isDashboard,
  sortingHandler,
}: any) {
  let navigate = useNavigate();
  const { userId } = useSelector((state: RootState) => state.user);
  const [exportData, setExportData] = useState<StudentListCSVItem[]>([]);
  const [textValue, setTextValue] = useState("");
  const [searched, setSearched] = useState(false);
  const getExportData = async (): Promise<StudentListCSVItem[]> => {
    let filterExpression = { userId: { eq: userId } };

    // get bookmarks
    const response = await API.graphql({
      query: queries.listHeiBookmarks,
      variables: { filter: filterExpression, limit: 100000 },
    });

    const responseObject = JSON.parse(JSON.stringify(response));
    const heiBookmarks = responseObject.data.listHeiBookmarks.items;

    // check if candidate is bookmarked
    const getIsCandidateBookmarked = (candidateUserId: string) => {
      if (!heiBookmarks || heiBookmarks.length === 0) return false;

      let bookmarkedCandidates = heiBookmarks[0].candidateUserId;
      const currentCandidateBookmark = bookmarkedCandidates.filter(
        (item: string) => item === candidateUserId,
      );

      if (currentCandidateBookmark.length > 0) return true;

      return false;
    };

    // fabricate data for export
    const dataToExport = studentList.map((item: any) => ({
      bookmarked: getIsCandidateBookmarked(item.userAssessmentState.user.userId)
        ? "Yes"
        : "No",
      name: item.userAssessmentState.user.name,
      ageGroup: item.userAssessmentState.user.ageGroup,
      enrollmentYear: item.userAssessmentState.user.enrollmentYear,
      leadershipScoreBand: item.leadershipScoreBand,
      perseveranceScoreBand: item.perseveranceScoreBand,
      communicationScoreBand: item.communicationScoreBand,
      innovativeThinkingScoreBand: item.innovativeThinkingScoreBand,
      comment:
        item.userAssessmentState.heiComments.items.length > 0
          ? item.userAssessmentState.heiComments.items.filter(
              (comment: any) => comment.userId === userId,
            ).length > 0
            ? item.userAssessmentState.heiComments.items.filter(
                (comment: any) => comment.userId === userId,
              )[0].comment
            : ""
          : "",
    }));

    return dataToExport;
  };

  // set export data on initial load
  useEffect(() => {
    const setInitialExportData = async () => {
      const dataToExport = await getExportData();
      setExportData(dataToExport);
    };

    setInitialExportData();
  }, []);

  // handle any subsequent changes to the bookmarked students
  useEffect(() => {
    const prepareExportData = async () => {
      if (debouncedRefreshingCSVData.status) {
        const dataToExport = await getExportData();
        setExportData(dataToExport);
        setRefreshingCSVData({ status: false });
      }
    };

    prepareExportData();
  }, [studentList, debouncedRefreshingCSVData]);

  var headers = [
    { label: "Flagged Students", key: "bookmarked" },
    { label: "Name", key: "name" },
    { label: "Age Range", key: "ageGroup" },
    { label: "Enrolled Since", key: "enrollmentYear" },
    { label: "Leadership", key: "leadershipScoreBand" },
    { label: "Effective Communication", key: "communicationScoreBand" },
    { label: "Perseverance", key: "perseveranceScoreBand" },
    { label: "Innovative Thinking", key: "innovativeThinkingScoreBand" },
    // {
    //   label: "My Comments",
    //   key: "comment",
    // },
  ];

  const handleExportClick = async () => {
    const data = exportData;

    EventService.track({
      event_type: "user",
      event_name: "hei_list_export",
      user_type: "hei",
      event_data: {
        students: data,
      },
    });
  };

  const handleGoToSupport = () => {
    navigate("/fullsummary/support/panel");
    setSelectedTab("support");
  };

  const search = () => {
    if (textValue === "") return;
    sortingHandler({
      propertyKey: textValue.toLocaleLowerCase(),
      sortDirection: "search",
    });
    setSearched(true);
  };

  const clearSearch = () => {
    sortingHandler({
      propertyKey: [],
      sortDirection: "search",
    });
    setTextValue("");
    setSearched(false);
  };

  const handleKeyDown = (e: any) => {
    console.log(e);
    if (e.keyCode === 13) {
      search();
    }
  };

  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-baseline pt-3 ${isDashboard ? "mb-1" : "mb-3"}`}
      >
        <div>
          <h2 className="screenTitle m-0 pt-3">Individual Reports</h2>
          <span style={{ color: "#60616A", fontSize: "14px" }}>
            {isDashboard
              ? "Displaying the top five individuals in each skill level"
              : "Select a student name to view individual SkillTrack insights."}
          </span>
        </div>
        <div className="text-center">
          <div className="exportLink">
            {isDashboard ? (
              <a className="student-list-link" href={"/fullsummary/students"}>
                <ChevronRight />
              </a>
            ) : (
              <CSVLink
                filename={"studentlist.csv"}
                data={exportData}
                headers={headers}
                onClick={() => handleExportClick()}
              >
                <span>
                  <ExportIcon />
                </span>
                &nbsp;Export
              </CSVLink>
            )}
            {/* <span>
              <ExportIcon />
            </span>
            &nbsp;Export */}
          </div>
        </div>
      </div>

      <div className="legendContainer d-flex justify-content-between pb-4">
        <div>
          <div className="screenInstructions">
            {!isDashboard && (
              <input
                type="text"
                placeholder="Search by student name"
                className="search-bar"
                value={textValue}
                style={{
                  backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  border: 0,
                  width: 400,
                  height: 31,
                  padding: 11,
                  outline: "none",
                }}
                onChange={(e) => {
                  setTextValue(e.target.value);
                  setSearched(false);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            )}
            {!isDashboard ? (
              !searched ? (
                <button
                  style={{
                    marginLeft: -34,
                    borderRadius: 10,
                    border: 0,
                    color: "#397f93",
                  }}
                  onClick={() => search()}
                >
                  <SearchIcon />
                </button>
              ) : (
                <button
                  style={{ marginLeft: -34, borderRadius: 10, border: 0 }}
                  onClick={() => clearSearch()}
                >
                  <ClearIcon width={20} />
                </button>
              )
            ) : (
              ""
            )}
          </div>
          {/* <button className="btn-gotosupport" onClick={handleGoToSupport}>
            Go to Student Resource Hub
          </button> */}
        </div>
        <SkillsLegend />
      </div>
    </div>
  );
}

export default ScreenHeader;
