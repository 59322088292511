export function dateConversion(str: string) {
  var currentDate = new Date(str);

  var date = currentDate.getDate();
  var month = currentDate.getMonth(); //Be careful! January is 0 not 1
  var year = currentDate.getFullYear();

  var dateString = month + 1 + "/" + date + "/" + year.toString().substr(-2);
  return dateString;
}

const defaults = {
  dateConversion,
};

export default defaults;
