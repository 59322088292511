// Packages
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import {
  updateUserBIQ,
  updateUserAssessmentState,
  updateUserConfidenceLevels,
} from "../../../redux/actions";

// Components
import BIQCongratulationsPage from "../../../reskin/pages/BIQ/CongratulationsPage/BIQCongratulationsPage";

// Assets
import BIQQuestionWrapper from "../../../reskin/pages/BIQ/BIQQuestionWrapper/BIQQuestionWrapper";

//Dynamic query
import { getOrList } from "../../../components/dynamicqueries";

//Graphql
import { listBIQS } from "../../../graphql/queries";

// Store
import { RootState } from "../../../redux/reducers";

// Services
import EventService from "../../../services/EventService/event.service";

// Models
import { AssessmentStatusType, BIQ } from "../../../models";

const ReskinBIQ = () => {
  // User Info
  const { user } = useSelector((state: RootState) => state);
  const {
    userId,
    assessmentState: { UserAssessmentStateId },
  } = user;

  // Package Actions
  let navigate = useNavigate();
  const dispatch = useDispatch();

  // set any previously stored responses
  const initialResponse = {
    enrollmentStatus: user.enrollmentStatus || "",
    programYear: user.programYear || "",
    fieldOfStudy: user.fieldOfStudy || "",
    transferToFourYear: user.transferToFourYear || "",
    ageGroup: user.ageGroup || "",
    gender: user.gender || "",
    goal: user.goal || "",
    emplStatus: user.emplStatus || "",
    ethnicity: user.ethnicity || "",
  };

  const [biq, setBiq] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [biqResponse, setResponse] = React.useState<any>(initialResponse);
  const [showCompletionPage, setShowCompletionPage] =
    React.useState<boolean>(false);

  const initialConfidenceResponse = {
    perseveranceConfidenceScore: 0,
    leadershipConfidenceScore: 0,
    effectiveCommunicationConfidenceScore: 0,
    innovativeThinkingConfidenceScore: 0,
  };

  const [confidenceResponse, setConfidenceResponse] = React.useState<any>(
    initialConfidenceResponse,
  );

  React.useEffect(() => {
    async function fetchBiqs() {
      try {
        const sort = {};
        let biqData = await getOrList(listBIQS, "listBIQS", sort);
        biqData = biqData.sort(
          (a: any, b: any) => parseFloat(a.sequence) - parseFloat(b.sequence),
        );

        setBiq(biqData);
      } catch (err) {
        console.warn(err);
        setBiq({ error: "Unable to fetch questions." });
      }
    }

    fetchBiqs();
  }, []);

  const questionPageSequence = [
    // Each item in this array represents a page of questions.
    [0, 1],
    [2],
    [3],
    [4],
    [5],
    [6],
  ];

  const getCurrentQuestions = () => {
    const currentQuestionIndexes = questionPageSequence[currentPage];
    if (currentQuestionIndexes) {
      const currentQuestions = currentQuestionIndexes.map((questionIndex) => {
        return {
          ...biq[questionIndex],
        };
      });

      return currentQuestions;
    } else {
      return [];
    }
  };

  const handleBIQCompletion = () => {
    // onSuccess
    // if (
    //     responseValidated()
    //     // && areConfidenceAnwersValid
    // ) {
    // form is locked when there was a previous response
    // if (!formLocked) {
    dispatch(updateUserBIQ({ userId, ...biqResponse }));
    dispatch(updateUserConfidenceLevels({ userId, ...confidenceResponse }));
    // dispatch(updateUserConfidenceLevels({
    //     userId,
    //     // ...confidenceResponse
    // }))

    EventService.track({
      event_type: "user",
      event_name: "user_submit_biq",
      user_type: "student",
      event_data: {
        ...biqResponse,
        ...confidenceResponse,
        submit_succeeded: true,
      },
    });
    // }

    setShowCompletionPage(true);
    // dispatch(
    //     updateUserAssessmentState({
    //     userId,
    //     UserAssessmentStateId,
    //     statusType: AssessmentStatusType.BIQ_COMPLETE,
    //     })
    // );

    // navigate("/assessment/communication/intro");
    //   }
  };

  const handleNext = () => {
    if (currentPage === questionPageSequence.length - 1) {
      handleBIQCompletion();
    } else {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
    }
  };

  const handlePrevious = () => {
    const previousPage = currentPage - 1;
    setCurrentPage(previousPage);
  };

  if (showCompletionPage) {
    return <BIQCongratulationsPage />;
  }
  const checkConfidenceResponseValidity = () => {
    let areAllConfidenceAnswersValid = true;
    const confidenceResponseKeys = Object.keys(confidenceResponse);
    confidenceResponseKeys.forEach((attribute) => {
      const isAttributeValid: boolean = confidenceResponse[attribute] > 0;
      if (!isAttributeValid) areAllConfidenceAnswersValid = false;
    });
    return areAllConfidenceAnswersValid;
  };

  return (
    <>
      {biq && !biq.error && (
        <BIQQuestionWrapper
          questions={getCurrentQuestions()}
          questionTotal={7}
          currentResponse={biqResponse}
          currentPage={currentPage}
          confidenceResponse={confidenceResponse}
          setConfidenceResponse={setConfidenceResponse}
          initialConfidenceResponse={initialConfidenceResponse}
          checkConfidenceResponseValidity={checkConfidenceResponseValidity}
          handlePrevious={() => handlePrevious()}
          handleNext={() => handleNext()}
          handleResponseChange={(newResponse: any) => setResponse(newResponse)}
        />
      )}
    </>
  );
};

export default ReskinBIQ;
