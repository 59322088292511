// Packages
import React from "react";
import { Auth } from "aws-amplify";

// Models
import { Usertype } from "../../../models";

// Services
import EventService from "../../../services/EventService/event.service";
import UserService from "../../../services/User/user.service";

//Graphql
import createOrUpdate, { getOrList } from "../../../components/dynamicqueries";
import { createUser } from "../../../graphql/mutations";
import { listInstitutions } from "../../../graphql/queries";

// Components
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";

// Styles
import "./SignUp.scss";
import "@aws-amplify/ui-react/styles.css";
import { Form, Button } from "react-bootstrap";
import { PasswordInput } from "../../institution/components";
import { validateEmail } from "../../../utilities/emailVerification";

export default function SignUp({ changeAuthState, userType }: any) {
  const [registerError, setRegisterError] = React.useState<string>("");
  const [validated] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<any>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    institution: "",
    enrollmentYear: "",
    enrollmentSemester: "",
    roleTitle: "",
    terms: false,
  });
  const [errors, setErrors] = React.useState<any>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    institution: "",
    enrollmentYear: "",
    enrollmentSemester: "",
    roleTitle: "",
    terms: "",
  });
  const [displayTerms, toggleTerms] = React.useState<boolean>(false);
  const [Institutions, setInsitutions] = React.useState<any[]>([]);
  const [filter, openFilter] = React.useState<any>(false);
  const [selectedInsitution, setselectedInsitution] =
    React.useState<string>("");

  React.useEffect(() => {
    EventService.page({
      type: "enter",
      page: "User Create Account",
    });

    return () => {
      EventService.page({
        type: "exit",
        page: "User Create Account",
      });
    };
  }, []);

  // query for Institutions
  if (Institutions.length === 0) {
    getOrList(listInstitutions, "listInstitutions", {
      SortDirection: "ASC",
    }).then((result) => {
      let suggestions = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        if (element.id !== "pinnacle") suggestions.push(element.name);
      }

      setInsitutions(suggestions.sort());
    });
  }

  const checkValidity = async () => {
    let errors = 0;

    let errorValues = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      institution: "",
      enrollmentYear: "",
      enrollmentSemester: "",
      roleTitle: "",
      terms: "",
    };

    if (!validateEmail(formData.email.trim())) {
      errorValues.email = "Please enter a valid email address.";
      errors++;
    }

    const isValidUser = await UserService.isValidInstitutionUser(
      formData.email.trim(),
      selectedInsitution,
    );

    // check against InsitutionUser table
    if (
      formData.email.trim() !== "" &&
      userType === Usertype.INSTITUTION &&
      !isValidUser
    ) {
      errorValues.email = `Uh oh! We don’t have a record of your institution for the current study. Please reach out to <a href="mailto:skilltracksupport@ets.org">PSQ Help</a> for assistance.`;
      errors++;
    }

    if (formData.password.trim() === "") {
      errorValues.password = "Password cannot be empty.";
      errors++;
    }

    if (formData.firstName.trim() === "") {
      errorValues.firstName = "Enter your first name.";
      errors++;
    }

    if (formData.lastName.trim() === "") {
      errorValues.lastName = "Enter your last name.";
      errors++;
    }

    if (
      formData.institution === "" ||
      formData.institution === "Select an institution"
    ) {
      errorValues.institution = "Please select your institution.";
      errors++;
    }

    if (formData.terms !== true) {
      errorValues.terms = "You must agree to the terms and conditions";
      errors++;
    }

    if (
      userType === Usertype.STUDENT &&
      formData.enrollmentYear.trim() === ""
    ) {
      errorValues.enrollmentYear = "Select year of enrollment.";
      errors++;
    }

    if (
      userType === Usertype.STUDENT &&
      formData.enrollmentSemester.trim() === ""
    ) {
      errorValues.enrollmentSemester = "Select semester of enrollment.";
      errors++;
    }

    if (userType === Usertype.INSTITUTION && formData.roleTitle.trim() === "") {
      errorValues.roleTitle = "Specify your role or title.";
      errors++;
    }

    if (errors > 0) {
      setErrors(errorValues);
      return false;
    }

    return true;
  };

  const handlePasswordKeyDown = async (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSignUp(event);
    }
  };

  const generateEnrollmentYears = () => {
    const currentYear = new Date().getFullYear();
    let years: number[] = [];
    for (let i = currentYear - 2; i !== currentYear + 2; i++) {
      years.push(i);
    }
    return years;
  };

  const semesters = ["Spring", "Summer", "Fall", "Winter"];

  const handleSignUp = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setErrors({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      institution: "",
      enrollmentYear: "",
      enrollmentSemester: "",
      roleTitle: "",
      terms: "",
    });

    // setValidated(true);

    const isValid = await checkValidity();

    if (isValid === true) {
      const customAttribute = "custom:userType";
      try {
        const userData = await Auth.signUp({
          username: formData.email,
          password: formData.password,
          attributes: {
            email: formData.email,
            [customAttribute]: userType,
          },
        });

        // need to get cognito id
        const { userSub: userId } = userData;

        // insert into dynamo
        const input = {
          userId,
          name: `${formData.firstName} ${formData.lastName}`,
          enrollmentYear: Number(formData.enrollmentYear),
          enrollmentSemester: formData.enrollmentSemester,
          email: formData.email,
          institutionId: selectedInsitution,
          userType: userType,
        };

        const dstest = await createOrUpdate(createUser, input);

        // store locally for confirmation
        sessionStorage.setItem("email", formData.email);
        sessionStorage.setItem("userId", userId);

        //track event
        EventService.track({
          event_type: "user",
          event_name: "user_register",
          user_type: "student",
          event_data: {
            success: isValid,
            failure_reason: registerError,
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            institution: formData.institution,
            enrollment_year: formData.enrollmentYear,
            enrollment_semester: formData.enrollmentSemester,
            role_title: formData.roleTitle,
          },
        });

        // change to verify account state
        changeAuthState({ screen: "Verify" });
      } catch (error: unknown) {
        console.log(error);

        // typescript doesn't like those unknowns
        const customError = error as string;
        const errorHandling = customError.toString().split(":")[0];
        setRegisterError(errorHandling);

        switch (errorHandling) {
          case "InvalidParameterException":
            setErrors({ ...errors, email: "Enter a valid email address." });
            break;

          case "InvalidPasswordException":
            setErrors({ ...errors, password: "Invalid password format." });
            break;

          case "UsernameExistsException":
            setErrors({
              ...errors,
              email: "An account already exists for this email.",
            });
            break;

          default:
            console.log(customError);
            setErrors({ email: "An error occured, try again.", password: "" });
            break;
        }
      }
    }
  };

  const handleFilter = () => {
    openFilter(!filter);
  };

  const onSelectedInstitution = (result: string) => {
    if (result === "Select an institution" || result === "") {
      setErrors({ ...errors, institution: "Please select your institution." });
    } else {
      setErrors({ ...errors, institution: "" });
      setselectedInsitution(result);
      setFormData({ ...formData, institution: result });
    }
  };

  const handleInputChange = (e: any) => {
    let { value, dataset, checked } = e.target;
    const { prop } = dataset;

    if (prop === "terms") {
      value = checked;
    }

    setFormData({
      ...formData,
      [prop]: value,
    });

    // // reset specific prop user is in
    setErrors({ ...errors, [prop]: "" });
  };

  return (
    <div className={displayTerms ? "BottomContainer" : "BottomContainerSignUp"}>
      {!displayTerms && (
        <div className="sign-up-container">
          <div className={displayTerms ? "" : "ImageContainer"}>
            <div className="TopContainer">
              <div className="text-container header-container">
                <p className="Header m-0">SkillTrack</p>
              </div>
              <div className="text-container subtext-container">
                <p className="SubTitleText m-0">
                  SkillTrack will help you identify your strengths in
                  employability skills.
                </p>
              </div>
            </div>
            <div
              className={
                displayTerms ? "BottomContainer" : "BottomContainerSignUp"
              }
            >
              {displayTerms ? "" : <p className="boldText">Sign Up</p>}
              <div className="InputContainer">
                <div className="InputContainer">
                  <section className="signup">
                    <Form
                      className="form"
                      noValidate
                      validated={validated}
                      onSubmit={(e: any) => handleSignUp(e)}
                    >
                      <div className="items">
                        <Form.Group className="mb-3 item" controlId="formEmail">
                          <Form.Label className="reskin-form-label">
                            Email &nbsp; <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            className="TextInputContainer"
                            type="email"
                            placeholder=""
                            tabIndex={1}
                            data-prop={"email"}
                            onChange={handleInputChange}
                            required
                            value={formData.email}
                            isInvalid={errors.email !== ""}
                            autoComplete="off"
                          />
                          <Form.Control.Feedback type="invalid">
                            <span
                              dangerouslySetInnerHTML={{ __html: errors.email }}
                            />
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          className="mb-2 item"
                          controlId="formBasicPassword"
                        >
                          <div className="form-section-container">
                            <PasswordInput
                              tabIndex={2}
                              handleInputChange={handleInputChange}
                              error={errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-3 item"
                          controlId="formFirstName"
                        >
                          <Form.Label className="reskin-form-label">
                            First Name &nbsp;{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            className="TextInputContainer"
                            type="text"
                            placeholder=""
                            data-prop={"firstName"}
                            onChange={handleInputChange}
                            required
                            tabIndex={3}
                            isInvalid={errors.firstName !== ""}
                            value={formData.firstName}
                            autoComplete="off"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          className="mb-3 item"
                          controlId="formLastName"
                        >
                          <Form.Label className="reskin-form-label">
                            Last Name &nbsp;{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            className="TextInputContainer"
                            type="text"
                            placeholder=""
                            data-prop={"lastName"}
                            onChange={handleInputChange}
                            required
                            tabIndex={4}
                            value={formData.lastName}
                            isInvalid={errors.lastName !== ""}
                            autoComplete="off"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          className="mb-3 item"
                          controlId="formInstitution"
                        >
                          <Form.Label className="reskin-form-label">
                            Your Institution that Requests SkillTrack &nbsp;{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          {errors.institution !== "" && (
                            <div className="invalid-feedback d-block mb-2">
                              {errors.institution}
                            </div>
                          )}

                          <select
                            className="custom-select"
                            onChange={(e) =>
                              onSelectedInstitution(e.target.value)
                            }
                            tabIndex={5}
                          >
                            <option selected>Select an institution</option>
                            {Institutions.map(
                              (institution: string, index: number) => {
                                return (
                                  <option value={institution} key={index}>
                                    {institution}
                                  </option>
                                );
                              },
                            )}
                          </select>
                        </Form.Group>

                        <Form.Group
                          className="mb-3 item"
                          controlId="formEnrollmentYear"
                        >
                          <Form.Label className="reskin-form-label">
                            Year of Enrollment &nbsp;{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          {errors.enrollmentYear !== "" && (
                            <div className="invalid-feedback d-block">
                              {errors.enrollmentYear}
                            </div>
                          )}
                          <div className="radios">
                            {generateEnrollmentYears().map(
                              (year, index: number) => {
                                return (
                                  <Form.Check type="radio" key={index}>
                                    <Form.Check.Input
                                      id={`year-${year}`}
                                      type="radio"
                                      name="enrollmentYear"
                                      data-prop={"enrollmentYear"}
                                      value={year}
                                      checked={formData.enrollmentYear == year}
                                      isValid
                                      tabIndex={6}
                                      onChange={handleInputChange}
                                    />
                                    <Form.Check.Label
                                      className="reskin-form-label"
                                      htmlFor={`year-${year}`}
                                    >
                                      {year}
                                    </Form.Check.Label>
                                  </Form.Check>
                                );
                              },
                            )}
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-3 item"
                          controlId="formEnrollmentSemester"
                        >
                          <Form.Label className="reskin-form-label">
                            Semester of Enrollment &nbsp;{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          {errors.enrollmentSemester !== "" && (
                            <div className="invalid-feedback d-block">
                              {errors.enrollmentSemester}
                            </div>
                          )}
                          <div className="radios">
                            {semesters.map((semester, index: number) => {
                              return (
                                <Form.Check type="radio" key={index}>
                                  <Form.Check.Input
                                    id={`semester-${semester}`}
                                    type="radio"
                                    name="enrollmentSemester"
                                    data-prop={"enrollmentSemester"}
                                    value={semester}
                                    checked={
                                      formData.enrollmentSemester === semester
                                    }
                                    isValid
                                    tabIndex={7}
                                    onChange={handleInputChange}
                                  />
                                  <Form.Check.Label
                                    className="reskin-form-label"
                                    htmlFor={`semester-${semester}`}
                                  >
                                    {semester}
                                  </Form.Check.Label>
                                </Form.Check>
                              );
                            })}
                          </div>
                        </Form.Group>
                      </div>

                      <div className="tnc-check">
                        <Form.Group
                          className="item checks"
                          controlId="formTerms"
                          style={{ padding: "0px" }}
                        >
                          <Form.Check type="checkbox">
                            <Form.Check.Input
                              id="terms"
                              type="checkbox"
                              name="terms"
                              tabIndex={8}
                              data-prop={"terms"}
                              role="checkbox"
                              className="terms-checkbox"
                              onChange={handleInputChange}
                              checked={formData.terms}
                              isInvalid={
                                formData.terms !== true && errors.terms !== ""
                              }
                            />
                            <Form.Check.Label
                              className="reskin-form-label"
                              htmlFor="terms"
                              style={{ marginTop: "4px" }}
                            >
                              I agree to the&nbsp;
                              <a
                                href="#terms"
                                className="tnc-text"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  toggleTerms(!displayTerms);
                                }}
                              >
                                Terms and Conditions
                              </a>
                            </Form.Check.Label>
                          </Form.Check>

                          {formData.terms !== true && errors.terms !== "" && (
                            <div className="invalid-feedback d-block">
                              {errors.terms}
                            </div>
                          )}
                        </Form.Group>
                      </div>

                      <div className="controls-container d-flex align-items-center justify-content-center mt-5 ml-2">
                        <Button
                          bsPrefix="psq-btn"
                          variant="reskin-primary"
                          type="submit"
                          className="btn-signin"
                          tabIndex={9}
                        >
                          Next
                        </Button>
                      </div>
                      <div className="create">
                        Already have an account?&nbsp;
                        <button
                          className="link-as-btn"
                          onClick={(e: any) => {
                            e.preventDefault();
                            changeAuthState({ screen: "SignIn" });
                          }}
                        >
                          Sign In
                        </button>
                      </div>
                    </Form>
                    {/* </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {displayTerms && (
        <div className="sign-up-container">
          <TermsAndConditions back={() => toggleTerms(false)} />
        </div>
      )}
    </div>
  );
}
