// environmental variables object
let env: any = {};

// stage
let stage: string = process.env.REACT_APP_ENV || "staging";

// default settings
env.default = {
  stage,
};

// dev variables
env.dev = {
  assessmentName: "SampleAssessment",
};

// demo variables
env.demo = {
  assessmentName: "SampleAssessment",
};

// stagin variables
env.staging = {
  assessmentName: "A",
};

// demo variables
env.test = {
  assessmentName: "A",
};

// demo variables
env.prod = {
  assessmentName: "A",
};

env.prd = {
  assessmentName: "A",
};

env.ets = {
  assessmentName: "A",
};

// demo variables
env.production = {
  assessmentName: "A",
};
// Sets the environment object with the desired "staged" version
env = { ...env["default"], ...env[stage] };

export default env;
