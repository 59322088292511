export function userSupportReducer(state: any, action: any) {
  switch (action.type) {
    case "add":
      return [...state, action.value];

    case "set":
      return action.value;

    default:
      throw new Error();
  }
}
